@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans 3", sans-serif;
}

.app{
  display: flex;
  position: relative;
}

.app .sidebar-expanded {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5); 
}

::-webkit-scrollbar{
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track{
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb{
  background: #888;
}

/* handle on hover */
::-webkit-scrollbar-thumb:hover{
  background: #555;
}